 const e = {
"BASE_URL": "https://api.prospecer.com",
"HOSTNAME": "prospecer-app-z2mj8",
"IMAGES_BASEURL": "https://prospecer.nyc3.cdn.digitaloceanspaces.com/",
"REPORTING": "1",
"AUTH0_AUDIENCE": "https://prospecer.com/",
"AUTH0_URI": "https://app.prospecer.com/login",
"APP_PLATFORM_INDEX_DOCUMENT": "index.html",
"PWD": "/workspace/fe",
"CNB_STACK_ID": "heroku-22",
"CNB_BUILDPACK_DIR": "/cnb/buildpacks/digitalocean_custom/0.1.3",
"REPORTING_URL": "https://reports.prospecer.com",
"BUILD_COMMAND": "cd fe; node bin/env_to_js.js > src/env.js; yarn build",
"HOME": "/home/apps",
"DD_ON": "1",
"AUTH0_DOMAIN": "login.prospecer.com",
"APP_PLATFORM_COMPONENT_TYPE": "static_site",
"LIBRARY_PATH": "/layers/heroku_nodejs-engine/yarn/lib:/layers/heroku_nodejs-engine/nodejs/lib",
"SHLVL": "1",
"PROD": "1",
"LD_LIBRARY_PATH": "/layers/heroku_nodejs-engine/yarn/lib:/layers/heroku_nodejs-engine/nodejs/lib",
"AUTH0_CLIENT_ID": "Mt6ObNuc238TsoanijCfL21K2M9lhoR7",
"STACK": "heroku-22",
"PATH": "/layers/heroku_nodejs-engine/yarn/bin:/layers/heroku_nodejs-engine/toolbox/bin:/layers/heroku_nodejs-engine/nodejs/bin:/opt/apps-go/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin",
"CPATH": "/layers/heroku_nodejs-engine/nodejs/include",
"OLDPWD": "/workspace",
"_": "/layers/heroku_nodejs-engine/nodejs/bin/node",
}

export default e
